body,
html {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  scroll-behavior: smooth;
}
body > #root {
  height: 100%;
  width: fit-content;
}
